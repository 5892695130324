import { useMemo } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import type { GetServerDataProps, GetServerDataReturn, PageProps } from 'gatsby'
import { Link } from 'gatsby'

type Props = PageProps

const useErrorState = (location: Location) =>
  useMemo(() => {
    const params = new URLSearchParams(location.search)
    const fromUrl = decodeURI(params.get('from') ?? location.pathname)

    return {
      fromUrl,
    }
  }, [location.pathname, location.search])

function Page({ location }: Props) {
  const { fromUrl } = useErrorState(location)

  return (
    <>
      <GatsbySeo noindex nofollow />

      <h1>Not Found: 404</h1>
      <div>This app could not find url {fromUrl}</div>
      <div>
        Lo sentimos no pudimos encontrar esa página. <br />
        Probá nuevamente realizando una búsqueda desde nuestra página de{' '}
        <Link to="/">inicio</Link>
      </div>
    </>
  )
}

export const getServerData = async (
  context: GetServerDataProps
): GetServerDataReturn => {
  const { from } = context.query as { from: string }

  if (!from) {
    return {}
  }

  const getCategories = from
    .split('%2F')
    .filter((e: string) => e !== '')
    .map((e: string) => e.replace('}', ''))
    .filter((e: string) => e !== 'p')
    .map((e: string, i: number, row: string[]) => {
      if (i === 0 && row.length > 1) {
        return `&category-1=${e}`
      }

      if (i + 1 === row.length) {
        return `?q=${e}`
      }

      return `&category-${i + 1}=${e}`
    })

  const facets = from
    .split('%2F')
    .filter((e: string) => e !== '')
    .map((_, i: number, row: string[]) => {
      if (i + 1 === row.length) {
        return null
      }

      return `category-${i + 1}`
    })
    .filter((e: string | null) => e !== null)

  const newURL =
    getCategories.length > 1
      ? `/s/${getCategories[getCategories.length - 1].replace(
          '-',
          '+'
        )}${getCategories.slice(0, -1).join('')}&facets=${facets.join(
          ','
        )}&sort=score_desc&page=0`
      : `/s/${getCategories[0]
          .replace(/-/g, '+')
          .replace(/\d/g, '')
          .replace('litro', '')}&sort=score_desc&page=0`

  return {
    status: 301,
    headers: {
      Location: newURL,
    },
  }
}

export default Page
